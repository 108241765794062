export const formatSeconds = {
  filters: {
    formatSeconds(value) {
      if (Number.isNaN(value)) return "00:00:00";
      let theTime = Math.ceil(value); // 秒
      let middle = 0; // 分
      let hour = 0; // 小时

      if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
      }

      let result =
        parseInt(theTime) >= 10
          ? "" + parseInt(theTime)
          : "0" + parseInt(theTime);
      middle = middle >= 10 ? "" + parseInt(middle) : "0" + parseInt(middle);
      hour = hour >= 10 ? "" + parseInt(hour) : "0" + parseInt(hour);

      return hour + ":" + middle + ":" + result;
    },
    mixinRetainYMD(value) {
      return value.substring(0, 10);
    },
  },
};
export const mixinRetainYMD = {
  filters: {
    mixinRetainYMD(value) {
      if (typeof value === "string") return value.substring(0, 10);
    },
  },
};
