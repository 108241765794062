<template>
  <div class="single-detail-container">
    <div class="left">
      <com-introduction :data="detail"></com-introduction>
    </div>
    <div class="right">
      <com-top-info :data="infoData">
        <template slot="btns">
          <com-button :icon="require('../assets/play.png')" @click="play()"
            >播放</com-button
          >
          <com-button
            :icon="require('../assets/pj.png')"
            type="shallow"
            @click="scoreShow = true"
            >评价</com-button
          >
        </template>
      </com-top-info>
      <!--      <div class="lyric">-->
      <!--        <div>歌词<img src="../assets/gc.png" /></div>-->
      <!--        <div>纯音乐，请您欣赏</div>-->
      <!--      </div>-->
      <div ref="scoreList">
        <com-score-list
          :list="scoreList"
          :total="scoreListTotal"
          @load="scoreListLoad"
        ></com-score-list>
      </div>
      <com-score
        v-if="scoreShow"
        :visible.sync="scoreShow"
        @submit="scoreSubmit($event)"
      ></com-score>
    </div>
  </div>
</template>
<script>
import comIntroduction from "@/components/com/com-introduction.vue";
import comTopInfo from "@/components/com/com-top-info.vue";
import comScoreList from "@/components/com/com-score-list";
import comScore from "@/components/com/com-score";
import comButton from "@/components/com/com-button";
import { Single } from "@/class/music";
import { score } from "@/mixin/score";
export default {
  mixins: [score],
  components: {
    comIntroduction,
    comTopInfo,
    comScoreList,
    comScore,
    comButton,
  },
  data() {
    return {
      id: "",
      detail: {},
      single: null,
      singleId: null,
      scorePage: 1,
    };
  },
  watch: {
    "$route.params.id": {
      immediate: true,
      handler(v) {
        this.id = v;
      },
    },
  },
  computed: {
    infoData() {
      let { detail } = this;
      return {
        name: detail.name,
        user: detail.singer,
        type: detail.musicTypeName,
        times: detail.times,
        releaseTime: detail.releaseTime,
      };
    },
    userId() {
      return this.$store.state.user.userInfo?.id;
    },
  },
  methods: {
    sendGetDetail() {
      //singleDetailAndSongs  params->musicId
      this.$api.singleDetail({ id: this.id }).then((resolve) => {
        // console.log(resolve.data);

        // resolve.data.releaseTime = resolve.data.musicReleaseTime;
        // resolve.data.name = resolve.data.musicName;
        // resolve.data.cover = resolve.data.musicCover;
        // resolve.data.times = resolve.data.musicTimes;
        // resolve.data.id = resolve.data.musicId;
        // resolve.data.introduce = resolve.data.musicIntroduce;

        this.detail = resolve.data;
        this.single = new Single(resolve.data);
        this.singleId = this.detail.id;
        this.scoreSingleList(this.detail.id);
      });
    },
    play() {
      this.single.play(this);
    },
    scoreListLoad() {
      this.detail && this.detail.id && this.scoreSingleList(this.detail.id);
    },
    scoreSubmit($event) {
      if (!this.userId) {
        this.$message.info("请先登录");
        return;
      }
      this.$api
        .getIsComment({ musicId: this.singleId, userId: this.userId })
        .then((resolve) => {
          // console.log(this.singleId, this.detail);
          let params = {
            score: $event,
            musicId: ~~this.id,
            ownerId: this.detail.userId,
            ownerName: this.detail.userName,
            musicName: this.detail.musicName,
            id: resolve.data ? resolve.data.id : null,
          };
          this.scoreSingleSubmit(params);
          // resolve.data === null
          //   ? this.scoreSingleSubmit(
          //       $event,
          //       this.singleId,
          //       this.detail.musicUserId
          //     )
          //   : this.scoreSingleSubmit(
          //       $event,
          //       this.singleId,
          //       this.detail.musicUserId,
          //       resolve.data.id
          //     );
        });
    },
  },
  mounted() {
    document.addEventListener("scroll", () => {
      if (this.$refs.scoreList) {
        // console.log(this.$refs.scoreList.offsetHeight,this.$refs.scoreList.offsetTop)
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;

        if (scrollTop + windowHeight + 180 > scrollHeight) {
          //写后台加载数据的函数
          this.scoreListLoad();
          // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
        }
      }
    });

    this.sendGetDetail();
  },
};
</script>
<style scoped lang="scss">
.single-detail-container {
  display: flex;
  width: 100%;
  margin-top: 68px;
}
.single-detail-container .right {
  flex-grow: 1;
  width: 0;
  margin-left: 100px;
}
.lyric {
  > div:nth-child(1) {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    letter-spacing: 1px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    img {
      width: 15px;
      margin-left: 10px;
      margin-top: -3px;
    }
  }
  > div:nth-child(2) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #696b6f;
  }
}
</style>
