class Music {
  constructor(options) {
    this.id = options.id;
    this.name = options.name;
    this.image = options.cover;
    this.singer = options.singer;
    this.name = options.name;
    this.songs = options.listName;
    this.duration = options.duration;
    this.date = options.releaseTime;
    this.url = options.url;
    this.type = options.type;
    this.playNum = options.num;
  }
  addAttribute(o) {
    Object.keys(o).forEach((item) => {
      this[item] = o[item];
    });
  }
}

export class Single extends Music {
  constructor(options) {
    super(options);
    this.listId = options.playListId;
    this.shelves = options.shelves;
  }
  play(vm) {
    vm.$store.commit("ADD_PLAYLIST", this);
  }
}

export class Songs extends Music {
  constructor(options) {
    super(options);
    this.user = options.gmtCreateUserName || options.userName;
    this.date = options.gmtCreate;
    this.listenNum = options.timer;
  }
}
